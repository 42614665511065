import React from "react"

import SEO from "../components/seo"
import MainLayout from "../layouts/main-layout"
import ContactSection from "../components/contact-section"

const ContactPage = () => (
  <MainLayout>
    <SEO title="Contact" />
    <ContactSection />
  </MainLayout>
)

export default ContactPage
